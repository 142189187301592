  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  svg {
    background-color: #FFF;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    // height: 500px;
    // max-height: 100vh;
    height: 100vh;
    width: 100%;
  }
  
  svg:not(.active):not(.ctrl) {
    cursor: crosshair;
  }
  
  path.link {
    fill: none;
    stroke: #000;
    stroke-width: 4px;
    cursor: default;
  }
  
  svg:not(.active):not(.ctrl) path.link {
    cursor: pointer;
  }
  
  path.link.selected {
    stroke-dasharray: 10,2;
  }
  
  path.link.dragline {
    pointer-events: none;
  }
  
  path.link.hidden {
    stroke-width: 0;
  }
  
  circle.node {
    stroke-width: 1.5px;
    cursor: pointer;
  }
  
  circle.node.reflexive {
    stroke: #000 !important;
    stroke-width: 2.5px;
  }
  
  text {
    font: 12px sans-serif;
    pointer-events: none;
  }
  
  text.id {
    text-anchor: middle;
    font-weight: bold;
  }